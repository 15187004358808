import { BuyOrderItem } from "./BuyOrderItem";
import { Order } from "./Order";

export class BuyOrder extends Order
{
    supplier;
    misc_cost;
    taxno;

    /*
    get totalPrice() {
        return this.priceAfterDiscount + this.delivery_cost * 1 + this.misc_cost * 1;
    }
    */

    get isSupplierChangable() {
        return this.status == 'draft'
    }

    get supplierId() {
        return this.supplier.id
    }

    get supplierName() {
        return this.supplier.name
    }

    get supplierTaxNo() {
        return !!this.supplier.taxno ? this.supplier.taxno : '0000000000000'
    }

    get supplierAddress() {
        return this.supplier.address
    }

    constructor(parameters) {
        super(parameters, false);

        const { supplier, misc_cost, taxno } = parameters

        this.supplier = supplier        
        this.misc_cost = misc_cost
        this.taxno = taxno
    }

    addOrderItem(buyOrderItem) {
        if (!(buyOrderItem instanceof BuyOrderItem)) throw "can add only BuyOrderItem Class"
        
        this.orderItems.push(buyOrderItem)
    }

    serialize() {
        return {
            items: this.orderItems.map(data => {
                return {
                  id: data.id,
                  quantity: data.quantity,
                  discount: data.discount,
                  discount_type: data.discount_type,
                  note: data.note,
                  price: data.price,
                }
            }),            
            taxno: this.taxno,
            address: this.address,
            note: this.note,                        
            delivery_cost: this.delivery_cost,
            discount: this.discount,
            discount_type: this.discount_type,            
            warehouse_id: this.warehouseId,
            supplier_id: this.supplierId,
            created_at: this.created_at,
            misc_cost: this.misc_cost
        }
    }
}